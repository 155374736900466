import React from "react";

export const DataCard = ({
  title,
  description,
  imgURL,
  classRow,
  smclassRow,
  bgColor,
}) => {
  return (
    <aside
      className={`flex flex-col sm:flex-col ${bgColor} text-black py-4 px-2 sm:p-8`}
    >
      <h2 className="text-3xl sm:text-5xl font-bold my-6 flex justify-center text-rgb-navbar">
        {title}
      </h2>
      <div
        className={`flex ${classRow} ${smclassRow} p-1 sm:p-4 gap-x-8 justify-center`}
      >
        <img
          src={imgURL}
          alt="Equipo Pitranes"
          className="w-full sm:w-[90%] lg:w-[60%]"
          style={{ borderRadius: "5px" }}
        />
        <p className="text-base sm:text-2xl my-8 sm:my-1 px-4 flex flex-col text-justify">
          {description}
        </p>
      </div>
    </aside>
  );
};
