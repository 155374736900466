import React from 'react'
import { normasSeguridad } from '../../data/seguridad';
import MisionCard from '../../components/about/mision-card';
import SocialMedia from '../../components/about/social-media';

export default function RulesPage () {
  return (
    <main className='mt-8 w-[full]'>
        <section className='flex flex-col text-center gap-y-8 px-4 sm:px-7'>
            <h2 className='text-rgb-navbar text-3xl sm:text-5xl font-bold'>SEGURIDAD</h2>
            <p className='text-base sm:text-xl text-justify'> <strong> Pitranes S.A.S</strong> brinda a sus clientes un instructivo de seguridad audiovisual que 
                permite dar a conocer tanto al conductor como a los pasajeros las medidas preventivas 
                de seguridad para que su viaje sea seguro y placentero, no cumplir con estas normas puede 
                acarrear multas y accidentes, lo cual es responsabilidad de cada usuario.</p>
        </section>
    <div className="flex flex-col sm:grid sm:grid-cols-2 space-y-2 mb-6 mt-6 items-center p-6 gap-4 bg-zinc-100">
        {normasSeguridad.map((norma) => {
            return (
                <MisionCard
                    key={norma.id}
                    text={norma.text}
                    icon={norma.icon}
                    typeFlex={'flex-col'}
                />
            );
        })} 
    </div>
    <footer className="flex flex-col bg-zinc-100 items-center py-4 my-4">
        <hr />
        <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-rgb-navbar text-center">CONTÁCTANOS</h2>
        <div className="p-1 text-base sm:text-xl flex flex-col justify-center px-10 gap-y-2">
          <p className="flex flex-col gap-y-4">
            <span className='flex justify-center sm:justify-start'>¿Tienes alguna pregunta?.</span>
            <span>Ponte en contacto con nosotrosa través de nuestro número <strong>3214868249</strong></span>
          </p>
          <span>Dirección: <strong>Calle 2 Sur # 4 - 116. Barrio Trinidad</strong> Pitalito-Huila</span>
          <SocialMedia typeflex={'flex-row'} smtypeflex={'sm:flex-row'} />
        </div>
      </footer>
  </main>
  )
}
