import question from "../assets/icons/contact/question.svg";
import call from "../assets/icons/contact/call.svg";
import address from "../assets/icons/contact/address.svg";
import SocialMedia from "../components/about/social-media";

export const contactosImagenes = [
  {
    id: 1,
    icono: question,
    titulo: "¿Tienes alguna pregunta?",
    descripcion: (
      <SocialMedia typeflex={"flex-row"} smtypeflex={"sm:flex-col"} />
    ),
  },
  {
    id: 2,
    icono: call,
    titulo: "Llamenos",
    descripcion: (
      <span className="py-2 pl-10 sm:pl-0">
        Número telefónico <strong>(+57) 3214868249</strong>
      </span>
    ),
  },
  {
    id: 3,
    icono: address,
    titulo: "Dirección",
    descripcion: (
      <span className="py-2">
        Calle 2 Sur # 4 - 116. <strong>Barrio Trinidad</strong> Pitalito-Huila
        
      </span>
      

    ),
    
  },

];
