import users from '../assets/icons/policies/users.svg';
import service from '../assets/icons/policies/service.svg';
import develop from '../assets/icons/policies/develop.svg';
import planning from '../assets/icons/policies/planning.svg';

export const mision = [
    { id: 1, text: 'Usuarios', icon: users },
    { id: 2, text: 'Excelente servicio', icon: service },
    { id: 3, text: 'Desarrollo', icon: develop },
    { id: 4, text: 'Planificación', icon: planning },
]


