import { Link, useLocation } from "react-router-dom";
import { mobileUtilities, utilities } from "../../data/links.js";
import { useEffect, useRef, useState } from "react";
import Logo from '../../assets/icons/PITRANES_SAS_2.png';
import menuIcon from "../../assets/icons/navbar/menu.svg";

export const Navbar = ({ onSidebarToggle  }) => {     
  
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const pathname = location.pathname.substring(1);
    const foundUtility = utilities.find((utility) => utility.to === `/${pathname}`);
    setSelectedLink(foundUtility ? foundUtility.name : '');
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsSidebarOpen(false);
          onSidebarToggle(false);
        }, 0);
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onSidebarToggle]);

  const handleUtilityClick = (utility) => {
    setSelectedLink(utility.name);
    localStorage.setItem("selectedLink", utility.name);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => {
      const newState = !prevState;
      onSidebarToggle(newState);
      return newState;
    });
  };

  return (
    <nav className="bg-white border-b sm:border-b-2 border-rgb-navbar top-0 w-full fixed top-0 z-50">
      <div className="flex justify-between mx-2 sm:mx-12 sm:my-2 lg:items-center">
        <Link className="flex" to="/">
          <img src={Logo} style={{width: '300px'}} alt="icon"/>
        </Link>
        <div className="flex items-center pl-4 justify-end sm:justify-end sm:flex-row sm:gap-x-1 w-[70%] sm:w-full">
          <button className="block sm:hidden flex items-center rounded-full bg-rgb-navbar p-2" onClick={toggleSidebar}>
            <img src={menuIcon} style={{width: '30px', color: '#fff'}} alt="menuIcon" />
          </button>
          {isSidebarOpen && (
            <section className={`fixed top-0 left-0 h-full bg-gray-100 w-60 shadow-lg z-50 ${isSidebarOpen ? 'bg-zinc-100' : 'hidden'}`} ref={sidebarRef}>
              <div className="flex h-[35%] items-center">
              <img src={Logo} style={{width: '200px'}} alt="icon"/>
              </div>
              {mobileUtilities.map((mobileUtility) => {
              return (
                  <Link
                    key={mobileUtility.name}
                    to={mobileUtility.to}
                    className={`flex flex-row px-2 py-2 items-center text-center font-bold  ${
                      selectedLink === mobileUtility.name
                        ? "bg-white text-black border-2 border-rgb-navbar"
                        : "hover:bg-white text-black hover:text-rgb-navbar"
                    }`}
                    onClick={() => handleUtilityClick(mobileUtility)}
                  >
                    <div className="flex gap-x-4 items-center">
                      <img src={mobileUtility.icon} alt="icon" style={{ width: '30px'}}/>
                      {mobileUtility.name}
                    </div>
                  </Link>
              );
            })}
            </section>
          )}
          <div className="hidden sm:block sm:flex sm:flex-row">
            {utilities.map((utility) => {
              return (
                <Link
                  key={utility.name}
                  to={utility.to}
                  className={`flex flex-row px-2 py-2 items-center text-center font-bold rounded-lg ${
                    selectedLink === utility.name
                      ? "bg-white text-black border-2 border-rgb-navbar"
                      : "hover:bg-white text-black hover:text-rgb-navbar"
                  }`}
                  onClick={() => handleUtilityClick(utility)}
                >
                  {utility.name}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;