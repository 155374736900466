import React from 'react'
import { CardService } from '../../components/about/cardService/card-service'
import { serviciosImagenes } from "../../data/servicios";
import SocialMedia from '../../components/about/social-media';

export default function ServicePage() {
  return (
    <main className='w-[full] mt-8'>
      <section className='flex flex-col items-center gap-y-4 px-4'>
        <h2 className='text-rgb-navbar text-3xl sm:text-5xl font-bold'>SERVICIOS</h2>
        <p className='text-base text-center sm:text-justify sm:text-xl'>
          Ofrecemos los siguientes servicios de transporte en <strong>PITRANES SAS</strong>
        </p>
      </section>
      <div className="flex flex-col space-y mb-6 mt-6 items-center">
          {serviciosImagenes.map((servicioImagen, index) => {
            const isPar = index % 2 === 0;
            const cardClass = isPar ? 'justify-end' : 'justify-start';

            return (
              <div key={servicioImagen.id} className={`flex w-[full] sm:w-full p-4 ${cardClass}`}>
                <CardService
                  key={servicioImagen.id}
                  icon={servicioImagen.imgURL}
                  title={servicioImagen.titulo}
                  description={servicioImagen.descripcion}
                />
              </div>
            );
          })}
      </div>
      <footer className="flex flex-col bg-zinc-100 items-center py-4 my-4">
        <hr />
        <h2 className="text-2xl sm:text-3xl font-bold mb-2 text-rgb-navbar text-center">CONTÁCTANOS</h2>
        <div className="p-1 text-base sm:text-xl flex flex-col justify-center px-10 gap-y-2">
          <p className="flex flex-col gap-y-4">
            <span className='flex justify-center sm:justify-start'>¿Tienes alguna pregunta?.</span>
            <span>Ponte en contacto con nosotrosa través de nuestro número <strong>3214868249</strong></span>
          </p>
          <span>Dirección: <strong>Calle 2 Sur # 4 - 116. Barrio Trinidad</strong> Pitalito-Huila</span>
          <SocialMedia typeflex={'flex-row'} smtypeflex={'sm:flex-row'} />
        </div>
      </footer>
    </main>
  )
}