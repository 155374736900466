import ApretonManos from '../assets/images/preferencias/apretonManos.png';
import TrabajoEquipo from '../assets/images/preferencias/trabajoEquipo.png';
import CalidadSeguridad from '../assets/images/preferencias/calidadSeguridad.png';
import Innovacion from '../assets/images/preferencias/innovacion.png';

export const preferencias = [
    {
        id: 1,
        titulo: 'RESPONSABILIDAD',
        descripcion: 'Cumplimos con todas las normativas yregulaciones de transporte y contamoscon conductores altamente capacitados.',
        icono: ApretonManos,
        direccion: 'row-reverse'
    },
    {
        id: 2,
        titulo: 'TRABAJO EN EQUIPO',
        descripcion: 'Fomentamos un ambiente de colaboración y respeto entre los empleados, clientes demás personas.',
        icono: TrabajoEquipo,
        direccion: 'row'
    },
    {
        id: 3,
        titulo: 'CALIDAD Y SEGURIDAD',
        descripcion: 'Proporcionamos un servicio detransporte de alta calidad, eficientey puntual,garantizando la integridadfísica y emocional de nuestrospasajeros.',
        icono: CalidadSeguridad,
        direccion: 'row-reverse'
    },
    {
        id: 4,
        titulo: 'INNOVACIÓN',
        descripcion: 'Estamos siempre en búsqueda denuevas tecnologías y prácticas paramejorar la experiencia del cliente yla eficiencia de nuestros servicios.', 
        icono: Innovacion,
        direccion: 'row'
    }
]
