import seatbelt from '../assets/icons/security/seatbelt.svg';
import exitDoor from '../assets/icons/security/exitdoor.svg';
import handle from '../assets/icons/security/handle.svg';
import emergency from '../assets/icons/security/emergency.svg';
import notsmoke from '../assets/icons/security/notsmoke.svg';
import notreceive from '../assets/icons/security/notreceive.svg';

export const normasSeguridad = [
    { id: 1, text: 'Usar el cinturón de seguridad', icon: seatbelt },
    { id: 2, text: 'Salidas de emergencia ubicados en cada costado del vehículo', icon: exitDoor },
    { id: 3, text: 'Usar los pasamanos cuando el vehículo este en movimiento', icon: handle },
    { id: 4, text: 'Usar los extintores y botiquín en caso de emergencia', icon: emergency },
    { id: 4, text: 'Prohibido fumar dentro de los vehiculos', icon: notsmoke },
    { id: 4, text: 'No recibir objetos de extraños', icon: notreceive },
]


