import React from "react";

export const CardService = ({ icon, title, description }) => {
  return (
    <div
      className="flex w-[full] flex-col sm:flex-row p-3 sm:p-8 bg-rgb-navbar items-center sm:items-start"
      style={{ borderRadius: "20px" }}
    >
      <div className="flex flex-col p-1 mb-4 sm:mb-1 sm:ml-8 sm:mt-8 font-bold gap-y-4 sm:gap-y-10">
        <p className="text-white text-2xl text-center sm:text-justify sm:text-4xl">
          {title}
        </p>
        <p className="text-base sm:text-lg">{description}</p>
      </div>
      <div
        className="flex w-[60%] sm:h-[90%] p-2 gap-x-8 bg-transparent"
        style={{ borderRadius: "8px" }}
      >
        <img src={icon} style={{ width: "320px" }} alt="service" />
      </div>
    </div>
  );
};
