import { routes } from "./routes";
import { Route, Routes } from "react-router-dom";
import Navbar from "../components/initial/navBar";
import { useState } from "react";

export default function InnerRouter() {
  const [opacity, setOpacity] = useState(1);

  const handleSidebarToggle = (isSidebarOpen) => {
    setOpacity(isSidebarOpen ? 0.30 : 1);
  };

  return (
    <div className="flex flex-col h-auto w-full" >
      <header className="w-full">
        <Navbar onSidebarToggle={handleSidebarToggle} />
      </header>
      <article className="w-full mt-[20%] sm:mt-28" style={{ opacity }}>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={<route.element />}
            />
          ))}
        </Routes>
      </article>
    </div>
  );
}
