import img1 from '../assets/images/servicios/servicio1.png';
import img2 from '../assets/images/servicios/servicio2.png';
import img3 from '../assets/images/servicios/servicio3.png';
import img4 from '../assets/images/servicios/servicio4.png';
import img5 from '../assets/images/servicios/servicio5.png';

export const serviciosImagenes = [
    {
        id: 1,
        imgURL: img1,
        titulo: "Transporte de Salud",
        descripcion: "Trasportamos personal del sector salud, tanto de pacientes y acompañantes."
    },
    {
        id: 2,
        imgURL: img2,
        titulo: "Transporte Empresarial",
        descripcion: "Translado de instituciones y corporaciones públicas y privadas."
    },
    {
        id: 3,
        imgURL: img3,
        titulo: "Transporte Escolar",
        descripcion: "Servicio de transporte para instituciones educativas y universidades."
    },
    {
        id: 4,
        imgURL: img4,
        titulo: "Transporte de Turismo",
        descripcion: "Viajes a nivel nacional con la mayor comodidad y el mejor servicio."
    },
    {
        id: 5,
        imgURL: img5,
        titulo: "Transporte Eventos Particulares",
        descripcion: "Adaptamos nuestros servicios a lo que su evento requiere en cuanto a transporte de pasajeros."
    },
]