import React from 'react'
import SectionContact from '../../components/contact/sectionContact'
import { contactosImagenes } from '../../data/contact'

export default function Contact () {
  return (
    <main className='mt-8 flex flex-col items-center'>
        <section className='flex flex-col text-center gap-y-8 px-3 sm:px-7'>
            <h2 className='text-rgb-navbar text-3xl sm:text-5xl font-bold'>CONTÁCTENOS</h2>
            <p className='text-base sm:text-xl'> Para <strong> Pitranes S.A.S</strong> la prioridad de cada viaje es la 
                comodidad y seguridad que se le ofrece a nuestros clientes; contáctenos y realice el viaje que siempre quiso. 
            </p>
            <p className='text-base sm:text-xl text-center font-bold'>Su viaje es muy importante, permítenos viajar contigo.</p>
        </section>
        <section className='flex flex-col sm:flex-row mt-8 border-y-2 sm:border border-y-rgb-navbar gap-x-4 items-between px-3 sm:px-7 w-[98%]'>
        {contactosImagenes.map((contactoImagen) => {
            return (
                <SectionContact
                    key={contactoImagen.id}
                    icon={contactoImagen.icono}
                    title={contactoImagen.titulo}
                    description={contactoImagen.descripcion}
                />
            )})}
        </section>
    </main>
  )
}
