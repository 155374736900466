import React from 'react'

export const PreferCard = ({ icon, title, description, direction }) => {
  return (
    <main className="shadow-lg py-4" style={{borderRadius: '30px'}}>
    <div className={`flex flex-${direction} p-2 justify-start items-center`}>
      <div className="flex p-4 w-[60%] sm:w-[35%] sm:h-[20%] items-center justify-center rounded-full bg-rgb-navbar">
        <img src={icon} alt="iconPreference"/>
      </div>
      <div className='flex flex-col gap-y-4'>
        <section className="px-4 font-bold text-center text-base sm:text-xl">
            {title}
        </section>
        <section className="px-4 text-center text-base sm:text-xl">
            {description}
        </section>
      </div>
    </div>
  </main>
  )
}
